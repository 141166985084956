.mfa-modal-sidebar-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #eaf2f9;
}

.otp-modal-code-input-wrapper {
  max-width: 400px;
}

.right-align {
  margin-left: auto !important;
}

/*# sourceMappingURL=index.css.map */
