@import "@lightspeed/design-system-css/src/non-styles";
@import "@vend/business-components/dist/css/Authentication/index";

$signin-header-max-width: 1100px;
$signin-header-min-width: 320px;
$signin-header-height: 48px;
$signin-header-padding-h: 32px;

// ---- Copied from navigation/src/styles ------
$vd-topbar-height: 50px;
$vd-topbar-background-color: hs-color(bg-neutral-dark-default);
$vd-topbar-font-color: hs-color(fg-neutral-ondark-default);
$vd-topbar-item-side-padding: vd-grid-unit(5);
$vd-topbar-item-label-margin-left: 10px;
$vd-topbar-item-default-opactiy: 0.5 !default;
$vd-topbar-item-label-max-width: 150px;
// ---------------------------------------------

html,
body,
#react-root {
  height: 100%;
}

// We can hide the badge because we show the alternative legal text
// see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}

.vr-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

// Topbar
// ---- Copied from navigation/src/styles ------
.nv-topnav {
  position: relative;
  width: 100%;
  z-index: 1;

  @media print {
    display: none;
  }
}
.nv-topnav-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  align-self: flex-end;
  margin-left: auto;
  flex: 1;

  @include vd-viewport-media-max($vd-breakpoint-xsmall) {
    flex: initial;
  }
}
.vd-nav-item-action {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  color: inherit;

  // Overrides any base anchor styles
  &:active,
  &:focus,
  &:hover {
    color: inherit;
  }
}
.vd-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1 100%;
  height: $vd-topbar-height;

  box-sizing: border-box;
  background-color: $vd-topbar-background-color;

  font-family: $vd-font-primary;
  font-size: 15px;
  line-height: normal;
  color: $vd-topbar-font-color;

  .vd-nav-item-action {
    padding: 0 $vd-topbar-item-side-padding;
    opacity: $vd-topbar-item-default-opactiy;

    &:hover {
      opacity: 1;
    }
  }

  .vd-nav-item-icon + .vd-nav-item-label {
    margin-left: $vd-topbar-item-label-margin-left;
  }

  .vd-nav-item-label {
    max-width: $vd-topbar-item-label-max-width;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .vd-logo-container {
    display: flex;
    height: 100%;
  }

  .vd-logo {
    padding-left: 23px;
  }
}
.vd-nav-item {
  box-sizing: border-box;
  height: 100%;
}
// ---------------------------------------------
.vr-topbar {
  max-height: $vd-topbar-height;
  z-index: 1;
}

.vr-main {
  background-color: hs-color(bg-neutral-backdrop);
}

.vr-modal-sidebar {
  background-color: #eaf2f9;
}

.vr-logo {
  height: 44px;
}

.vr-flame-logo {
  height: 28px;
}

.vr-brand-logo {
  height: 22px;
}

.vr-signin-btn--loading {
  min-width: 120px;
}

.vr-btn-wrap {
  @include vd-viewport-media-min(small) {
    margin-top: vd-grid-unit(8);
  }
}

.vr-pannel-footer {
  flex-wrap: wrap;
}

// Credentials Step
.vr-card--subtitle {
  flex-wrap: wrap;
}

// OTP STEP
.vr-code-input-wrapper {
  max-width: 400px;
}

// Recovery Code STEP
.vr-btn-group {
  min-width: 182px;
}

// Confirm Store Step
.vr-input-suffix {
  line-height: 2.5;
}

.vr-store-field-wrap {
  display: flex;
  align-items: flex-end;

  .vr-store-field {
    flex: 1;
    margin: 0;
  }
}

@include vd-viewport-media-max(small) {
  .vr-topbar {
    display: none;
  }
}

@include vd-viewport-media-max(414px) {
  .vr-store-field-wrap {
    flex-direction: column;
    align-items: flex-start;

    .vr-store-field {
      width: 100%;
    }
  }

  .vr-input-suffix {
    margin-left: 0;
    align-self: flex-end;
    line-height: 1;
    margin-top: 12px;
  }
}

// Modal overrides
.vr-container {
  @include vd-viewport-media-max(small) {
    .vd-dialog {
      left: 0;
      right: 0;
    }

    .vd-modal-container {
      max-width: none;
      height: 100%;
      max-height: none;

      border-radius: 0;

      margin: 0;
    }

    .vd-modal-inner-container {
      max-height: none;
    }
  }

  @include vd-viewport-media-max(xsmall) {
    .vd-modal-container {
      height: 100%;
      margin-top: 0;
    }
  }
}

// Marketing background
.vd-signin-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hs-color-bg-neutral-backdrop);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  display: none;
}

@include vd-viewport-media-min(xlarge) {
  .vd-signin-background {
    display: block;
    left: 50%;
  }

  .vd-signin-background + .vr-main .vd-dialog {
    right: 50%;
  }
}
